export const paths = {
  index: '/',
  auth: {
    jwt: {
      login: '/auth/jwt/login',
      forgotPassword: '/auth/jwt/forgot-password',
      renewPassword: '/auth/jwt/renew-my-password',
      newNaber: '/auth/jwt/new-naber',
    },
  },
  dashboard: {
    index: '/',
    account: '/account',
    managementConsole: {
      index: '/management-console',
      establishment: '/management-console/establishment',
    },
    customerFlow: {
      index: '/customer-flow',
      customer: '/customer-flow/customer',
      flow: '/customer-flow/flow',
      flowDetail: '/customer-flow/flow/',
    },
    documents: {
      index: '/documents',
      manage: '/documents/manage',
      exported: '/documents/exported',
      deleted: '/documents/deleted',
    },
  },
  backoffice: {
    users: {
      index: '/backoffice/users',
    },
    organizations: {
      index: '/backoffice/organizations',
    },
    connectors: {
      index: '/backoffice/connectors',
    },
  },
  401: '/401',
  404: '/404',
  500: '/500',
}
